import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Config } from '../models/config.model';

@Injectable({ providedIn: 'root' })
export class ConfigService extends AbstractService<Config>  {

  getURLBase(): string {
    return '/config/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Config): void {
    delete obj._id;
  }
  preUpdate(obj: Config): void {
  }

  getConfigPorFranquia(idFranquia: string): Observable<Config | any> {
    return super.get('franquia/' + idFranquia);
  } s

  getConfigPorFranquiaPublic(idFranquia: string): Observable<Config | any> {
    return this.http.get('/public/config/franquia/' + idFranquia);
  }

}