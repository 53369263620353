import { Component, Injector } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, PublicEmpresaService, PublicFranquiaService, PublicQuestionarioService, Questionario } from 'lib-smart-core';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
})
export class QuestionnaireComponent extends AbstractComponent {

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  questionarios: Questionario[]

  constructor(
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    private questionarioService: PublicQuestionarioService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.loadFranquiaPorCodigo(codigo);
    }
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe({
      next: (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadQuestionarios();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe({
      next: (empresa: Empresa) => {
        this.empresa = empresa;
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  goToFeedback(idQuestionario) {
    this.router.navigate(['feedback', this.franquia.codigo, idQuestionario])
  }

  loadQuestionarios() {
    this.questionarioService.getAllPorEmpresa(this.idEmpresa).subscribe(
      (questionarios: Questionario[]) => {
        this.questionarios = questionarios;
      }
    );
  }

}
