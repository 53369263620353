import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerDataComponent } from './modules/customer-data/customer-data.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { LoginComponent } from './modules/login/login.component';
import { QuestionnaireComponent } from './modules/questionnaire/questionnaire.component';
import { ThanksComponent } from './modules/thanks/thanks.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'questionario/:codigo', component: QuestionnaireComponent },
  { path: 'feedback/:codigo/:idQuestionario', component: FeedbackComponent },
  { path: 'feedback2/:codigo/:idQuestionario/:reavalia', component: FeedbackComponent }, //@deprected
  { path: 'informar/dados/:codigo/:idQuestionario', component: CustomerDataComponent },
  { path: 'obrigado/:codigo/:idQuestionario', component: ThanksComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
