<div class="modal inmodal fade in" id="gerarNovaSenha" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Nova Senha</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome" class="form-control input-lg" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="dadosSenha.nome">
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group">
              <label>Telefone</label>
              <input type="text" mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                placeholder="Insira o Telefone" class="form-control input-lg" name="numero" id="numero"
                #numero="ngModel" [(ngModel)]="dadosSenha.numero">
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" placeholder="Insira o Email" class="form-control input-lg" name="email" id="email"
                #email="ngModel" [(ngModel)]="dadosSenha.email">
            </div>
          </div>
        </div>
        <div class="hr-line-dashed"></div>
        <ng-container *ngIf="!filaSelecionada">
          <div class="row">
            <ng-container *ngFor="let fila of filas">
              <div class="col-md-6" *ngIf="fila.visivelTablet">
                <!-- <button *ngIf="!fila.preferencial" [disabled]="loading" class="btn btn-primary btn-xlg btn-block"
                  style="height: 55px;" (click)="gerarNovaSenhaMetadata(fila)">
                  {{fila.nome | uppercase}}
                </button> -->
                <button [disabled]="loading" class="btn btn-primary btn-xlg btn-block" style="height: 55px;"
                  (click)="selecionarFila(fila)">
                  {{fila.nome | uppercase}}
                </button>
                <br />
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="showNormalPreferencial && !showDadosContatoFila">
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;" [disabled]="loading"
                (click)="selecionarNormalPreferencial(false)">NORMAL</button>
              <br />
              <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;" [disabled]="loading"
                (click)="selecionarNormalPreferencial(true)">PREFERENCIAL</button>
              <br />
              <!-- <button class="btn btn-secondary btn-xlg btn-block" style="height: 55px;" (click)="setNormalPreferencial(false)">VOLTAR</button> -->
              <br />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="showDadosContatoFila">
          <div class="form-group form-group-lg" *ngFor="let item of filaSelecionada.campos; let i = index">
            <input type="text" id="campo_{{i}}" name="campo_{{i}}" placeholder="{{item.key}}" class="form-control">
          </div>
        </ng-container>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal" #btnCloseModalNovaSenha>Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="gerarNovaSenhaMetadata()"
          [disabled]="loading || !showConfirmarButton">Salvar e continuar</button>
      </div>
    </div>
  </div>
</div>

<div style="display: none;">
  <button type="button" class="btn btn-white" data-toggle="modal" data-target="#gerarNovaSenha"
    #btnShowModalNovaSenha>Show modal</button>

</div>