<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <img src="/assets/img/logo-icon.svg" alt="Smartline" width="37" />
      </a>
    </div>
    <div></div>
  </div>
</nav>