import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Atendimento } from '../models/atendimento.model';
import { Franquia } from '../models/franquia.model';

@Injectable({ providedIn: 'root' })
export class AtendimentoService extends AbstractService<Atendimento>  {

  getURLBase(): string {
    return '/atendimentos/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

  getAtendimentosPorEmpresa(idEmpresa: string, data: Date): Observable<Atendimento[] | any> {
    return super.get('empresa/' + idEmpresa + '/' + data.getTime());
  }

  getAtendimentosPorFranquia(idFranquia: string, data: Date): Observable<Atendimento[] | any> {
    return super.get('franquia/' + idFranquia + '/' + data.getTime());
  }

  getCountPorDataEmpresaUltimos7dias(idEmpresa: string, data: Date): Observable<any> {
    return super.get('count/empresa/ultimos7dias/' + idEmpresa + '/' + data.getTime());
  }

  getCountPorDataFranquiaUltimos7dias(idFranquia: string, data: Date): Observable<any> {
    return super.get('count/franquia/ultimos7dias/' + idFranquia + '/' + data.getTime());
  }


  getCountDataFranquia(idEmpresa: string): Promise<any> {

    var data1 = new Date();
    var data2 = new Date();
    var data3 = new Date();
    var data4 = new Date();

    data1.setDate(data1.getDate());
    data2.setDate(data2.getDate() - 1);
    data3.setDate(data3.getDate() - 2);
    data4.setDate(data4.getDate() - 3);

    var idFranquia = null;
    var counts = [];
    return new Promise((resolve, reject) => {
      resolve(true);
    }).then(() => {
      return this.http.get('/franquias/empresa/' + idEmpresa).toPromise().then(response => { return response }).catch(this.handleError2);
    }).then((franquias: Franquia[]) => {
      return franquias[0];
    }).then((franquia: Franquia) => {
      idFranquia = franquia._id;
      return super.get('/count/data/franquia/' + data1.getTime() + '/' + idFranquia).toPromise().then(response => { return response }).catch(this.handleError2);
    }).then((count: string) => {
      counts[0] = count;
      return super.get('/count/data/franquia/' + data2.getTime() + '/' + idFranquia).toPromise().then(response => { return response }).catch(this.handleError2);
    }).then((count: string) => {
      counts[1] = count;
      return super.get('/count/data/franquia/' + data3.getTime() + '/' + idFranquia).toPromise().then(response => { return response }).catch(this.handleError2);
    }).then((count: string) => {
      counts[2] = count;
      return super.get('/count/data/franquia/' + data4.getTime() + '/' + idFranquia).toPromise().then(response => { return response }).catch(this.handleError2);
    }).then((count: string) => {
      counts[3] = count;
    }).then(() => {
      return {
        periodos: [data1, data2, data3, data4],
        counts: [counts[0], counts[1], counts[2], counts[3]]
      };
    });
  }

  private handleError2(err: any): Promise<any> {
    console.log("Error: " + err);
    return Promise.reject(err.message || err);
  }

  getAtendimentoPorPeriodo(idFranquia: string, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get("http://localhost:3003/atendimentosPorPeriodo")
  }

}
