import { Empresa } from "../models/empresa.model";
import { Usuario } from "../models/usuario.model";

export class UtilHelper {

  private static instance: UtilHelper;

  constructor() { };

  static get getInstance() {
    if (UtilHelper.instance) {
      return UtilHelper.instance;
    }
    return new UtilHelper();
  };

  static no_image_user = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAD+ElEQVRoQ+2WS2gdZRTH/+ebSVLxRUVI1YXWtJUGm3QeN6m1+FipDbbiIohKtKa907QiUgQtuhAFNQsLPvC+6iOiuNBVcSUqtRqb5s7cGyNaI2hFN9aCj2pok8ycI9HNRZLmm8y9NYWZ7f2fc/6/8//uzEc4xx86x/0jBfi/E0wTSBNIuIH0CCVcYOLyNIHEK0zYIE0g4QITl9c9ge7SSGvExgCAHgHaGMTENEEkB6IWozC2zfo9seuaBnUFsItBr4iUFPOFpOhThhonEQWwDVIbwHwCSt0beO4H9YKoG4BbLN8lQu8wYdgIpd/flZmoNenmypYovMGgdgX01AuiLgCdubErTJmeIIOCC5r/uuXgtptPz7Xh7hdHLppuUp8phVZArQk894+kSdQFwC4EgwLZ06SiNaM7uo+dyZSVr7iKuAzgkcBzn18SAFZu9CjBOFYZcDbrGHIKZZ+ASd/L3KijP5MmeQIiZOWDGUPJoO9lHtcxZOVGS4C6vTrgrtDRNxzAKZZDgfFsxXOe0DHk5P0iiLcGXlerjr6xAACcgv8tQb7xvcwWHUPrXzkyQqYRVrPuJh19wwHsQrBPOHrQZLSVd3f9tMCfuFMRjwHYG3juc0sCwNpfuRIRHyWmYVKyOfDcmbmMdbz5xfnG5NQhQF21rGl69eHtG39dEgCzJuxicD+JvE6Qj0I2t48NWD/UmrPylXaDoiGB2ELG1krWeT+p+dn65G+hGhdOwb8vEsmRULNS8hULHScRIsJlEG4nkZMwjT4/mzlQD/N1A3AKfhsE/SC5A6C185tjZlI+Cb0Xsjk0PtD5S1KQRAlYr1YuN8JoUFjuYaUiEvkYpD40iKsE/lFm6OSswajZWE4sVxMkExHdpgRdzDhtKHmpxZx6erh/05+LBVk0gFPweyD8FgualaH2mRS+fGTHhuM6RuxSsJZEHoOgT0CvVTynX6duLs2iAP69ecrbEIwahty90P1nPnN2KXAkopWQMGcy1pV3d/0cFyQ2gF0KOiiMygLjUMupqS2H92w8FXdorf66/Z9fMhO1PBQCl/J55qPjfZ2TcfrFB8gFB0XJNWHU3P7lro7f4gybT+vmytdGkCG0mLdWH7BPxOkZC+Cfs8vyNQgPB1n3hTiDFtI6eX/2HmUHO907F9LW/h4LwC2UPQHlIzZW/vdDFWfoXNr1uepNSoXXV7LuMyAS3X6xAJyiv5cFT61a/v2yd3t7I90hOjorX7lBEX8CYFXgud/p1MT+kDkF/2IWtbq60/Z1B2jrnhRlraiuq3rWeMMS0DZzFoWxjtBZ9KU9KgXQXlWDhGkCDVqsdts0Ae1VNUiYJtCgxWq3TRPQXlWDhH8D9ol7QMAdMQsAAAAASUVORK5CYII=';

  static no_image_company = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAEp0lEQVR4Xu2czeuOWRjHv8gYKZnBQl7Cf6CokZXiD7CZHRtkZSPUWChlaUdKmd1YzmzYyMJCymYaKyshFl5Cybvx1oVST9d59Nz9nnNOv+/nXp775TrX93ye73m772eOOKwVmGOdPckLAMwhAAAAMFfAPH0cAADMFTBPHwcAAHMFzNPHAQDAXAHz9HEAADBXwDx9HAAAzBUwTx8HAABzBczTxwEAwFwB8/RxAAAwV8A8fRwAAMwVME8fBwAAcwXM08cBAMBcAfP0cQAAMFfAPH0cAADMFTBPHwcAAHMFzNPHAQDAXAHz9HtwgKjD4obt8Kxh7OahewBgiaQDDZU42jB289C9AHCkoRKHG8ZuHhoAJABojGF0AThAo0bAAXCARuh9D4sDNGyCnh3gtaSziTZR5x2SVibnrku6kpQHZDsLOjMGaAhghC45wEtJxwoA7JO0PjkXjX8+KV+mstUDAAA0VqBh+J67ABygAhgAwCygAmbjQzAGaNgEPTvAJ0lvCtoskDQ3Ofde0v+FgePPhWfFbKPV8aeku62CR9yeAWipS63YpyXdqRUsiwMALdWXAGDMOkDbpqkTHQAAgC6g9V5And96HgUHGOMA7yRdLbTOhm/3jZ6+J+lmcs9CSb8VnnW5AgERO+owegCAyV5A7DfEfgQAJCI4LAQBwBibBYAKfVApRM/rALNpMwgHwAEYA5QYGNcFHE9uCtfaK2ldci5mDReS8qWSDhYq8Eeh/EOhPOJn+xCxd/GxcA8OMMABogFKGyUrJGWbO/GVz9Mk1nxJqwp1uF0o/0fSw+TcRkmbkvInkv6WlIEDAAMAaDg0+hL6VAHAbZK2JxtpDySdLOxGAgAAMAaYdAyAA1RQoOdpYIX0x4agC6jUAqVZQIyoHxfq8IukGNiNHq8kvUjK50mKmUB2PCqUn5N0Pzm3WdKWpDzq+pekeCtp9GAMMGAMEAtBJ5L7wrV2SVqbnLsm6WJS/quk/YU6ZN8exKXxqlg2rQvwfkqeFdeWXi8DgIEAzJYPQwAAAJgFTDoLYC+gwiCs51kAAADArPk4lDHAgDHAW0mXCrOAeMUqm9bdknQjuWeRpK2FOmSbR3Hpf5KeJ/esKcxAwrHinmzmAAADAKhggGNDsBBUqQV6fSsYAAAg3Y5mN3CGwcABZljQSR7X8zRwkjymcS1dwDRUTZ6JA1QSOgvTswOwEFQBDACo8xcxrAMMWAfAAXAAloKnzQBdAF3AtBn74fNLs4B4vSs+nx49AtrfJa1Ozv0rKfvcO14h212oSfbW0Q8rPeEFewqfs/N5OP8Qwj+E9LoOMOGPfNDlOAAOgAPgAIPMY2Zu6nkWMDMZ9v0UuoBvXcChvttparU7w1/Ffv3WfvnUJO77wfEpe/bfxtVq3UMXUC1ZAuWLKuhirAAOYNz4kToAAIC5Aubp4wAAYK6Aefo4AACYK2CePg4AAOYKmKePAwCAuQLm6eMAAGCugHn6OAAAmCtgnj4OAADmCpinjwMAgLkC5unjAABgroB5+jgAAJgrYJ4+DgAA5gqYp48DAIC5Aubp4wAAYK6Aefo4AACYK2CePg4AAOYKmKf/GXx2OpD2BqlQAAAAAElFTkSuQmCC';

  static getUsuarioImage(usuario: Usuario): string {
    let image = this.no_image_user;
    if (usuario && usuario.image) {
      return usuario.image;
    }
    return image;
  }

  static getEmpresaImage(empresa: Empresa): string {
    let image = this.no_image_company;
    if (empresa && empresa.image) {
      return empresa.image;
    }
    return image;
  }

  static arrayBufferToBase64(buffer) {
    var binario = '';
    var bytes = new Uint8Array(buffer);
    var byteLength = bytes.byteLength;

    for (var i = 0; i < byteLength; i++) {
      binario += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binario);
  }

  static getDuracao(dataInicio: Date, dataFim: Date) {
    if (dataFim != null) {
      if (typeof dataInicio === 'string') {
        dataInicio = new Date(dataInicio);
      }
      if (typeof dataFim === 'string') {
        dataFim = new Date(dataFim);
      }
      const timeDiff = Math.abs(dataFim.getTime() - dataInicio.getTime());
      const diffDays = Math.ceil(timeDiff / (1000));
      if (diffDays < 60) {
        return 'menos de 1 minuto';
      } else if (diffDays == 60) {
        return '1 minuto';
      } else if (diffDays > 60) {
        let val = (diffDays / 60) + 1;
        let valstr = val.toString();
        valstr = valstr.substring(0, valstr.indexOf('.'));
        return (!!valstr ? valstr + ' minutos' : 'menos de 1 minuto');
      }
    }
    return '-';
  }

}