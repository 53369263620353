<nav class="navbar smartline-navbar">
  <div class="container-fluid">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </a>
    </div>
    <ul class="smartline-navbar__dropdown smartline-navbar__right navbar-right">

    </ul>
  </div>
</nav>
<div class="smartline-acompanhe">
  <div class="smartline-acompanhe__header">
    <h2 class="smartline-acompanhe__title">{{questionario?.nome}}</h2>
  </div>
  <div class="smartline-acompanhe__content">

    <p>Informe seus dados</p>

    <div class="form-group" style="text-align: left;">
      <label class="smartline__label" for="nome">Nome</label>
      <input type="text" class="form-control smartline__form" placeholder="Nome (opcional)" id="nome" name="nome"
        [(ngModel)]="nome">
    </div>

    <div class="form-group" style="text-align: left;">
      <label class="smartline__label" for="nome">Telefone</label>
      <input type="tel" placeholder="Telefone (opcional)" id="telefone" name="telefone" [(ngModel)]="telefone"
        mask="(00) 0000-0000||(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
        class="form-control smartline__form">
    </div>

    <div class="form-group" style="text-align: left;">
      <label class="smartline__label" for="nome">E-Mail</label>
      <input type="text" class="form-control smartline__form" placeholder="E-mail (opcional)" id="email" name="email"
        [(ngModel)]="email" email>
    </div>

    <div class="btn-feedback-ok">
      <button (click)="updateFeedback()" class="btn btn-block smartline__button--md"
        [ngStyle]="{'color': empresa?.textColor, 'background-color': empresa?.bgColor, 'border-color': empresa?.bgColor}">
        {{ questionario?.dadosContato ? 'Continuar' : 'Enviar' }}
      </button>
      <h5 class="nome-franquia">{{franquia?.nome}}</h5>
    </div>

  </div>
</div>
