import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule, CONSTANTES, ENV, SharedModule, customHttpProvider } from 'lib-smart-core';
import { ToastrModule } from 'ngx-toastr';
import { Constantes } from '../environments/contantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerDataComponent } from './modules/customer-data/customer-data.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { LayoutModule } from './modules/layout/layout.module';
import { LoginComponent } from './modules/login/login.component';
import { QuestionnaireComponent } from './modules/questionnaire/questionnaire.component';
import { ThanksComponent } from './modules/thanks/thanks.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FeedbackComponent,
    QuestionnaireComponent,
    ThanksComponent,
    CustomerDataComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    LayoutModule,
    SharedModule,
    ToastrModule.forRoot(),
    AlertModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    customHttpProvider,
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
