import { Component, Injector } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, Pergunta, PublicEmpresaService, PublicFranquiaService, Questionario } from 'lib-smart-core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
})
export class ThanksComponent extends AbstractComponent {


  codigo: string;
  idEmpresa: string;
  idFranquia: string;
  idQuestionario: string;
  questionario: Questionario;
  empresa: Empresa;
  franquia: Franquia;
  perguntas: Pergunta[];
  loading: boolean = false;
  reavalia: boolean = false;
  urlAvaliacaoExterna: string;
  labelAvaliacaoExterna: string;

  constructor(
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    this.urlAvaliacaoExterna = history.state.urlAvaliacaoExterna;
    this.labelAvaliacaoExterna = history.state.labelAvaliacaoExterna;
    this.reavalia = history.state.reavalia;

    this.codigo = super.getParam('codigo');
    this.idQuestionario = super.getParam('idQuestionario');

    if (this.codigo) {
      this.loadFranquiaPorCodigo(this.codigo);
    }

    if (this.reavalia) {
      setTimeout(() => {
        this.router.navigate(['feedback', this.franquia.codigo, this.idQuestionario], { queryParams: { reavalia: true } })
      }, 4000);
    }

  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe({
      next: (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe({
      next: (empresa: Empresa) => {
        this.empresa = empresa;
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

}
