<div>
  <nav class="navbar smartline-navbar">
    <div class="container-fluid">
      <div></div>
      <div class="smartline-navbar__center">
        <a class="" href="/">
          <div class="img-container">
            <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
              [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
          </div>
        </a>
      </div>
      <ul class="smartline-navbar__dropdown smartline-navbar__right navbar-right">
      </ul>
    </div>
  </nav>
  <section class="container-fluid">
    <div class="sso-tablet flex justify-content-between flex-direction-column height-85-vh" style="height: 85vh;">
      <div class="widget style1 row m-b-lg m-t-lg">
        <div class="col-xs-12 col-sm-12">
          <div class="logo text-center">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
          <h1 class="smartline__text">Sua opinião é muito importante para nós!</h1>
          <h3 class="smartline-acompanhe__title">Obrigado pela participação.</h3>
          <div *ngIf="!!urlAvaliacaoExterna" style="margin-top: 50px">
            <p>{{labelAvaliacaoExterna}}</p>
            <a class='btn btn-primary' [href]="urlAvaliacaoExterna">Avalie aqui</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="smartline-acompanhe__footer mt-5" style="display: flex; flex-direction: column;
          justify-content: center;">
          <img [src]="'/assets/img/smartline-hori.svg'" class="img-responsive" style="height: 20px;" alt="Smartline" />
          <p class="smartline-footer-url">www.smartline.com.br</p>
        </div>
      </div>
    </div>
  </section>
</div>
