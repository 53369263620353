import { Component, Injector } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AbstractComponent, Empresa, Feedback, Franquia, PublicEmpresaService, PublicFeedbackService, PublicFranquiaService, Questionario } from 'lib-smart-core';

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
})
export class CustomerDataComponent extends AbstractComponent {

  codigo: string;
  idEmpresa: string;
  idFranquia: string;
  idQuestionario: string;
  questionario: Questionario;
  empresa: Empresa;
  franquia: Franquia;
  loading: boolean = false;
  nome: string = '';
  telefone: string = '';
  email: string = '';
  idFeedback: string;
  navigationExtras: NavigationExtras;

  constructor(
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    private feedbackService: PublicFeedbackService,
    injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    this.codigo = super.getParam('codigo');
    this.idQuestionario = super.getParam('idQuestionario');
    this.navigationExtras = { state: history.state };
    this.idFeedback = history.state.idFeedback;
    if (this.codigo) {
      this.loadFranquiaPorCodigo(this.codigo);
    }
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe({
      next: (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe({
      next: (empresa: Empresa) => {
        this.empresa = empresa;
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  updateFeedback() {

    let feedback: Feedback = {
      _id: this.idFeedback,
      empresa: { _id: this.idEmpresa } as Empresa,
      franquia: { _id: this.idFranquia } as Franquia,
      questionario: { _id: this.idQuestionario } as Questionario,
      nome: this.nome,
      telefone: this.telefone,
      email: this.email,
    } as Feedback;

    this.feedbackService.update(feedback).subscribe(
      (feedback) => {
        this.router.navigate(['obrigado', this.codigo, this.idQuestionario], this.navigationExtras);
      }
    );

  }
}
