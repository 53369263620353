<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </a>
    </div>
    <ul class="smartline-navbar__dropdown smartline-navbar__right navbar-right">
    </ul>
  </div>
</nav>
<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 mt-5">
      <h2 class="smartline__heading3">Questionários</h2>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-12 flex flex-col flex-wrap align-items-center justify-content-between">
              <button *ngFor="let item of questionarios" class="btn btn-block smartline__button--md"
                style="white-space: normal;"
                [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                (click)="goToFeedback(item._id)">{{item.nome}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <h5 class="nome-franquia">{{franquia?.nome}}</h5>
    </div>
  </div>
</div>
