<nav class="navbar smartline-navbar">
  <div class="container-fluid">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </a>
    </div>
    <ul class="smartline-navbar__dropdown smartline-navbar__right navbar-right">
    </ul>
  </div>
</nav>
<div class="smartline-acompanhe">
  <div class="smartline-acompanhe__header">
    <h2 class="smartline-acompanhe__title">{{questionario?.nome}}</h2>
  </div>
  <div class="smartline-acompanhe__content">
    <div *ngFor="let pergunta of perguntas">
      <div *ngIf="pergunta.tipo === 'NOTA'" class="smartline-acompanhe__item">
        <div class="smartline-acompanhe__nota"></div>
        <p>{{pergunta.titulo}}</p>
        <div class="smartline-acompanhe__nota">
          <div class="smartline-acompanhe__item flex justify-content-center">
            <input type="hidden" id="pergunta_{{pergunta._id}}" name="{{pergunta._id}}" />
            <star-rating [starCount]="5" [rating]="getValueAsk(pergunta._id)"
              (onChangeRating)="changeRating($event, pergunta._id)"></star-rating>
          </div>
        </div>
      </div>
      <div *ngIf="pergunta.tipo === 'SIM_NAO'" class="smartline-acompanhe__item">
        <div class="smartline-acompanhe__nota"></div>
        <p>{{pergunta.titulo}}</p>
        <div class="smartline-acompanhe__nota">
          <div class="smartline-acompanhe__item">
            <input type="hidden" id="pergunta_{{pergunta._id}}" name="{{pergunta._id}}" />
            <button class="btn btn-default text-uppercase" id="sim_{{pergunta._id}}"
              (click)="avaliaSimNao(pergunta._id, pergunta.respostaPositiva, true)">&nbsp;&nbsp;SIM&nbsp;&nbsp;</button>&nbsp;&nbsp;
            <button class="btn btn-default text-uppercase" id="nao_{{pergunta._id}}"
              (click)="avaliaSimNao(pergunta._id, pergunta.respostaPositiva, false)">&nbsp;&nbsp;NÃO&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="questionario?.habilitarComentario" class="smartline-acompanhe__footer mt-3">
      <p>Gostaria de deixar algum comentário?</p>
      <div class="smartline-acompanhe__nota">
        <div class="form-group">
          <textarea class="form-control smartline__form" [(ngModel)]="comentario" rows="2" name="comentario"
            id="comentario"></textarea>
        </div>
      </div>
    </div>
    <div class="btn-feedback-ok">
      <button (click)="createFeedback()" class="btn btn-block smartline__button--md"
        [ngStyle]="{'color': empresa?.textColor, 'background-color': empresa?.bgColor, 'border-color': empresa?.bgColor}">
        {{ questionario?.dadosContato ? 'Continuar' : 'Enviar' }}
      </button>
      <h5 class="nome-franquia">{{franquia?.nome}}</h5>
    </div>
  </div>
</div>
