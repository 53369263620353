import { Component, Injector } from '@angular/core';
import { AbstractComponent, PublicFranquiaService } from 'lib-smart-core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends AbstractComponent {

  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
    private franquiaService: PublicFranquiaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.loading = true;
    this.franquiaService.getFranquiaPorCodigo(this.model.codigo)
      .subscribe({
        next: (f) => {
          console.log(f)
          if (f !== null) {
            this.router.navigate(['questionario', this.model.codigo])
          } else {
            this.alertService.error("Código do estabelecimento não encontrado!")
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => this.loading = false
      })

  }
}
