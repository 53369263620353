import { YoutubeHelper } from './youtube.helper';
import { Howl, Howler } from 'howler';

export class AudioHelper {

  _PATH: string;
  _EXTENSAO = '.mp3';
  _arraySounds: Array<string> = new Array<string>();
  _youtubeHelper: YoutubeHelper;

  _playing: Boolean = false;

  constructor() { }

  play(senha: string, mesa: string, tipoVoz: string, tipoChamada: string, youtubeHelper: YoutubeHelper) {
    tipoVoz = tipoVoz.toLowerCase();
    this._PATH = `../../assets/audiosenha/${tipoVoz}/`;

    if (!!youtubeHelper) {
      this._youtubeHelper = youtubeHelper;
    }

    console.log('this._playing', this._playing);

    if (this._playing) {
      setTimeout(() => {
        this.play(senha, mesa, tipoVoz, tipoChamada, youtubeHelper)
      }, 500);
      return;
    }
    this._arraySounds = new Array<string>();

    if (tipoVoz === 'alert') {
      this._arraySounds.push('words/' + tipoChamada);
    } else { // for male or female

      this._arraySounds.push('words/SENHA');

      let prefixo: string = senha.replace(/[^a-zA-Z.]/g, '');
      if (!!prefixo) {
        if (prefixo.length > 1) {
          for (let i = 0; i < prefixo.length; i++) {
            this._arraySounds.push('letters/' + prefixo.charAt(i).toUpperCase());
          }
        } else {
          this._arraySounds.push('letters/' + prefixo.toUpperCase());
        }
      }

      let numeroSenha: string = senha.replace(/[^0-9.]/g, '');
      let numeroSenhaInt: number = +numeroSenha;

      if (numeroSenhaInt <= 100) {
        this._arraySounds.push('numbers/' + numeroSenhaInt.toString());
      } else if (numeroSenhaInt > 100 && numeroSenhaInt < 200) {
        this._arraySounds.push('numbers/100E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 200) {
        this._arraySounds.push('numbers/200');
      } else if (numeroSenhaInt > 200 && numeroSenhaInt < 300) {
        this._arraySounds.push('numbers/200E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 300) {
        this._arraySounds.push('numbers/300');
      } else if (numeroSenhaInt > 300 && numeroSenhaInt < 400) {
        this._arraySounds.push('numbers/300E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 400) {
        this._arraySounds.push('numbers/400');
      } else if (numeroSenhaInt > 400 && numeroSenhaInt < 500) {
        this._arraySounds.push('numbers/400E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 500) {
        this._arraySounds.push('numbers/500');
      } else if (numeroSenhaInt > 500 && numeroSenhaInt < 600) {
        this._arraySounds.push('numbers/500E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 600) {
        this._arraySounds.push('numbers/600');
      } else if (numeroSenhaInt > 600 && numeroSenhaInt < 700) {
        this._arraySounds.push('numbers/600E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 700) {
        this._arraySounds.push('numbers/700');
      } else if (numeroSenhaInt > 700 && numeroSenhaInt < 800) {
        this._arraySounds.push('numbers/700E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 800) {
        this._arraySounds.push('numbers/800');
      } else if (numeroSenhaInt > 800 && numeroSenhaInt < 900) {
        this._arraySounds.push('numbers/800E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 900) {
        this._arraySounds.push('numbers/900');
      } else if (numeroSenhaInt > 900 && numeroSenhaInt < 1000) {
        this._arraySounds.push('numbers/900E');
        this._arraySounds.push('numbers/' + +numeroSenha.substring(1, 3).toString());
      } else if (numeroSenhaInt === 1000) {
        this._arraySounds.push('numbers/1000');
      } else {
        for (var i = 0; i < numeroSenha.length; i++) {
          this._arraySounds.push('numbers/' + +numeroSenha.charAt(i));
        }
      }

      if (tipoChamada && tipoChamada !== '') {
        this._arraySounds.push('words/' + tipoChamada);

        let prefixo: string = mesa.replace(/[^a-zA-Z.]/g, '');
        if (!!prefixo) {
          if (prefixo.length > 1) {
            for (let i = 0; i < prefixo.length; i++) {
              this._arraySounds.push('letters/' + prefixo.charAt(i));
            }
          } else {
            this._arraySounds.push('letters/' + prefixo);
          }
        }

        let numeroMesa: string = mesa.replace(/[^0-9.]/g, '');
        let numeroMesaInt: number = +numeroMesa;

        if (numeroMesaInt <= 100) {
          this._arraySounds.push('numbers/' + numeroMesaInt);
        } else if (numeroMesaInt > 100 && numeroMesaInt < 200) {
          this._arraySounds.push('numbers/100E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 200) {
          this._arraySounds.push('numbers/200');
        } else if (numeroMesaInt > 200 && numeroMesaInt < 300) {
          this._arraySounds.push('numbers/200E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 300) {
          this._arraySounds.push('numbers/300');
        } else if (numeroMesaInt > 300 && numeroMesaInt < 400) {
          this._arraySounds.push('numbers/300E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 400) {
          this._arraySounds.push('numbers/400');
        } else if (numeroMesaInt > 400 && numeroMesaInt < 500) {
          this._arraySounds.push('numbers/400E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 500) {
          this._arraySounds.push('numbers/500');
        } else if (numeroMesaInt > 500 && numeroMesaInt < 600) {
          this._arraySounds.push('numbers/500E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 600) {
          this._arraySounds.push('numbers/600');
        } else if (numeroMesaInt > 600 && numeroMesaInt < 700) {
          this._arraySounds.push('numbers/600E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 700) {
          this._arraySounds.push('numbers/700');
        } else if (numeroMesaInt > 700 && numeroMesaInt < 800) {
          this._arraySounds.push('numbers/700E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 800) {
          this._arraySounds.push('numbers/800');
        } else if (numeroMesaInt > 800 && numeroMesaInt < 900) {
          this._arraySounds.push('numbers/800E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 900) {
          this._arraySounds.push('numbers/900');
        } else if (numeroMesaInt > 900 && numeroMesaInt < 1000) {
          this._arraySounds.push('numbers/900E');
          this._arraySounds.push('numbers/' + +numeroMesa.substring(1, 3).toString());
        } else if (numeroMesaInt === 1000) {
          this._arraySounds.push('numbers/1000');
        } else {
          for (var i = 0; i < numeroMesa.length; i++) {
            this._arraySounds.push('numbers/' + numeroMesa.charAt(i));
          }
        }

      }

    }

    console.log('this._arraySounds', this._arraySounds);

    if (!!this._youtubeHelper) {
      this._youtubeHelper.setVolume(10);
    }

    this._playing = true;
    this.emitSound();
  }

  emitSound(index: number = 0) {

    if (index >= this._arraySounds.length) {
      if (!!this._youtubeHelper) {
        this._youtubeHelper.setVolume(50);
      }
      this._playing = false;
      return;
    }

    const sound = new Howl({
      src: [this._PATH + this._arraySounds[index] + this._EXTENSAO]
    });

    sound.on('end', () => {
      this.emitSound(index + 1);
    });
    sound.play();
  }

}