import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    private router: Router,
  ) {
  }

}