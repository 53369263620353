import { Component, Injector } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AbstractComponent, Empresa, Feedback, Franquia, Pergunta, PublicEmpresaService, PublicFeedbackService, PublicFranquiaService, PublicPerguntaService, PublicQuestionarioService, Questionario } from 'lib-smart-core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent extends AbstractComponent {

  codigo: string;
  idEmpresa: string;
  idFranquia: string;
  idQuestionario: string;
  questionario: Questionario;
  empresa: Empresa;
  franquia: Franquia;
  perguntas: Pergunta[];
  loading: boolean = false;
  reavalia: boolean = false;
  comentario: string = '';

  constructor(
    private franquiaService: PublicFranquiaService,
    private empresaService: PublicEmpresaService,
    private questionarioService: PublicQuestionarioService,
    private perguntaService: PublicPerguntaService,
    private feedbackService: PublicFeedbackService,
    injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    this.codigo = super.getParam('codigo');
    this.idQuestionario = super.getParam('idQuestionario');
    this.reavalia = (super.getQueryParam('reavalia') === 'true' ? true : false) || (super.getParam('reavalia') ? true : false);
    if (this.codigo) {
      this.loadFranquiaPorCodigo(this.codigo);
    }
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe({
      next: (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe({
      next: (empresa: Empresa) => {
        this.empresa = empresa;
        this.loadPerguntas();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  loadPerguntas() {
    if (this.idQuestionario === 'principal') {
      this.questionarioService.getQuestionarioPrincipalPorCodigoFranquia(this.codigo).subscribe(
        (questionario: Questionario) => {
          this.idQuestionario = questionario._id;
          this.questionario = questionario;
          this.perguntaService.getPerguntasPorQuestionario(this.idQuestionario).subscribe((perguntas: Pergunta[]) => this.perguntas = perguntas);
        }
      );
    } else {
      this.questionarioService.getById(this.idQuestionario).subscribe(
        (questionario) => {
          this.idQuestionario = questionario._id;
          this.questionario = questionario;
          this.perguntaService.getPerguntasPorQuestionario(this.idQuestionario).subscribe((perguntas: Pergunta[]) => this.perguntas = perguntas);
        }
      );
    }
  }

  avaliaSimNao(id, respostaPositiva, simNao) {
    let nota = ((respostaPositiva === 'SIM' && (simNao === true)) || (respostaPositiva === 'NAO' && (simNao === false)) ? 5 : 3);
    let inputHidden: HTMLInputElement = document.querySelector(`#pergunta_${id}`);

    if (inputHidden) {

      inputHidden.value = "" + nota;

      let btnSim = document.getElementById(`sim_${id}`);
      let btnNao = document.getElementById(`nao_${id}`);
      if (simNao) {
        btnSim.style.color = this.empresa.textColor;
        btnSim.style.backgroundColor = this.empresa.bgColor;
        btnNao.style.color = '';
        btnNao.style.backgroundColor = '';
      } else {
        btnNao.style.color = this.empresa.textColor;
        btnNao.style.backgroundColor = this.empresa.bgColor;
        btnSim.style.color = '';
        btnSim.style.backgroundColor = '';
      }
    }
  }

  getValueAsk(id: string): number {
    let element: HTMLInputElement = document.querySelector(`#pergunta_${id}`);

    return element ? parseInt(element.value) : 0;
  }

  changeRating(event: Event, id: string) {
    let element: HTMLInputElement = document.querySelector(`#pergunta_${id}`);
    if (element) {
      element.value = "" + event;
    }
  }

  createFeedback() {
    let feedback: Feedback = {
      empresa: { _id: this.idEmpresa } as Empresa,
      franquia: { _id: this.idFranquia } as Franquia,
      questionario: { _id: this.idQuestionario } as Questionario,
      comentario: this.comentario,
      respostas: []
    } as Feedback;

    this.perguntas.forEach((pergunta) => {
      let element: HTMLInputElement = document.querySelector(`#pergunta_${pergunta._id}`);
      feedback.respostas.push({
        resposta: (element ? element.value : 1),
        tipo: pergunta.tipo,
        pergunta: pergunta._id,
        valid: element.value !== "" ? true : false
      });
    });

    for (let i = 0; i < feedback.respostas.length; i++) {
      if (!feedback.respostas[i].valid) {
        this.alertService.error('Perguntas Obrigatórias!');
        return;
      }
    }

    this.feedbackService.create(feedback).subscribe(
      (feedback) => {

        const navigationExtras: NavigationExtras = {
          state: {
            reavalia: this.reavalia,
            urlAvaliacaoExterna: feedback.rating > 4.5 ? this.questionario.urlAvaliacaoExterna : '',
            labelAvaliacaoExterna: this.questionario.labelAvaliacaoExterna,
            idFeedback: feedback._id
          },
        };

        this.questionario.dadosContato ?
          this.router.navigate(['informar/dados', this.codigo, this.idQuestionario], navigationExtras) :
          this.router.navigate(['obrigado', this.codigo, this.idQuestionario], navigationExtras);
      }
    );
  }
}
